import { useMemo } from "react";
import type { Chain } from "viem";

import { getEntries } from "@/util/core";

export const useNetworks = (chains: Record<string, Chain>) =>
  useMemo(() => {
    const mainnets: (Chain & { key: string })[] = [];
    const testnets: (Chain & { key: string })[] = [];

    for (const [key, chain] of getEntries(chains)) {
      if (!chain.testnet) {
        mainnets.push({ ...chain, key });
      } else {
        testnets.push({ ...chain, key });
      }
    }

    return [mainnets, testnets] as const;
  }, [chains]);
