import T from "@marginly/ui/components/typography";
import { useRouter } from "next/navigation";
import { useMemo } from "react";
import type { Chain } from "viem";
import { useAccount } from "wagmi";

import { SimplePriceSVGGraph } from "@/components/common/simple-graph";
import Space from "@/components/common/space";
import { TokenIcon } from "@/components/common/token";
import { TradeBlock } from "@/components/common/trading-block";
import { CHAINKEY_MAP } from "@/constants/chains";
import { CHART_BACK_GREEN, CHART_BACK_RED } from "@/constants/common";
import { useWeb3Context } from "@/contexts/web3";
import { useMarginlyApi } from "@/hooks/api";
import { useBalanceNormalize } from "@/hooks/token";
import { useApply } from "@/hooks/util";
import { getPriceAggregates, toChartCandleSeries } from "@/util/common";
import { capitalize, noop } from "@/util/core";
import { formatNum } from "@/util/format";

import { AssetItem, BackButton, Percents, ThemeModalHeading } from "./styled";
import { ModalType } from "./types";

type Side = "long" | "short";

interface TradeDesc {
  tokenIn?: `0x${string}`;
  tokenOut?: `0x${string}`;
  side: Side;
}

interface TDProps extends TradeDesc {
  chain: Chain;
  uniswapAddress?: `0x${string}`;
  pool?: `0x${string}`;
}

function TradeDescription(props: TDProps) {
  const { address } = useAccount();

  // using useBalance instead of useToken cause it works with native tokens
  const tokenIn = useBalanceNormalize({
    address,
    token: props.tokenIn,
    chainId: props.chain.id,
  });

  const tokenOut = useBalanceNormalize({
    address,
    token: props.tokenOut,
    chainId: props.chain.id,
  });

  const { internalChainId, getPoolByAddress } = useWeb3Context();

  const isPendle = getPoolByAddress(props.pool)?.pool.isPendle;

  const { data: _candles } = useMarginlyApi({
    endpoint: "swapCandles",
    args: [internalChainId, props.uniswapAddress, isPendle ? "W" : "D"],
  });

  const candles = useMemo(
    () => _candles?.points.map(toChartCandleSeries),
    [_candles],
  );

  // const price = getPriceAggregates(candles);
  const price = useApply(getPriceAggregates, candles);

  return (
    <>
      <TradeBlock
        className={price?.up ? "up" : ""}
        style={{
          height: "64px",
          minWidth: "64px",
          flexGrow: 0,
          width: "64px",
          margin: "8px 0",
        }}
      >
        <SimplePriceSVGGraph
          candles={candles}
          width={64}
          height={64}
          color={price?.up ? CHART_BACK_GREEN : CHART_BACK_RED}
          className="wallet-graph"
        />
        {tokenOut.data?.symbol && (
          <TokenIcon
            token={tokenOut.data?.symbol}
            className="icon"
            style={{
              zIndex: 99,
              margin: "auto",
              filter: `invert(var(--svg-invert))`,
            }}
          />
        )}
      </TradeBlock>
      <div>
        <div>
          {capitalize(props.side)}&nbsp;
          {props.side === "long" ? "with" : "for"}&nbsp;
          {(props.side === "long" ? tokenIn : tokenOut).data?.symbol} +{" "}
          {(props.side === "short" ? tokenIn : tokenOut).data?.symbol}
        </div>
        <Percents className={price?.up ? "up" : ""}>
          <T caption>
            {price?.up ? "+" : ""}
            {formatNum(price?.percent, { dp: 2 })}% today
          </T>
        </Percents>
      </div>
    </>
  );
}

interface Props {
  address: `0x${string}`;
  chain: Chain;
  handleSwitchModal: (mt: ModalType, callback?: () => void) => void;
  onHide?: () => void;
}

export default function ModalTrade({
  address,
  chain,
  handleSwitchModal,
  onHide,
}: Props) {
  const router = useRouter();
  const { pools } = useWeb3Context();
  const chainName = CHAINKEY_MAP[chain.id];
  const account = useAccount();

  const { trades, weth } = useMemo(() => {
    let weth = false;

    const trades = pools
      .filter(({ baseToken, quoteToken }) =>
        [baseToken?.toLowerCase(), quoteToken?.toLowerCase()].includes(
          address.toLowerCase(),
        ),
      )
      .map(
        ({ address: pool, baseToken, quoteToken, unwrap, uniswapAddress }) => {
          const side: Side =
            baseToken?.toLowerCase() === address.toLowerCase()
              ? "long"
              : "short";

          const tokenIn =
            side === "long"
              ? unwrap === "base"
                ? undefined
                : baseToken
              : unwrap === "quote"
              ? undefined
              : quoteToken;

          const tokenOut =
            side === "long"
              ? unwrap === "quote"
                ? undefined
                : quoteToken
              : unwrap === "base"
              ? undefined
              : baseToken;

          if (!weth) {
            // weth name hack
            weth = side === "long" ? !tokenIn : !tokenOut;
          }

          return { pool, side, tokenIn, tokenOut, uniswapAddress };
        },
      );

    return { weth, trades };
  }, [pools, address]);

  const token = useBalanceNormalize({
    address: account.address,
    token: weth ? undefined : address,
    chainId: chain.id,
  });

  return (
    <>
      <BackButton onClick={handleSwitchModal.bind(null, "common", noop)}>
        <img
          src="/images/back-button.svg"
          alt=""
          style={{ filter: "invert(var(--svg-invert))" }}
        />
      </BackButton>

      <ThemeModalHeading>How to trade {token.data?.symbol}</ThemeModalHeading>
      <Space height={16} heightMobile={16} />

      {trades.map(({ pool, ...props }) => (
        <AssetItem
          key={pool}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleSwitchModal("common", () =>
              router.push(`/${chainName}/trade/${pool}`),
            );
            onHide?.();
          }}
        >
          <TradeDescription
            {...props}
            pool={pool}
            chain={chain}
            uniswapAddress={props.uniswapAddress}
          />
        </AssetItem>
      ))}

      <Space height={0} heightMobile={24} />
    </>
  );
}
