"use client";

import { useSetTheme, useThemeConfigStore } from "@marginly/ui/stores/theme";
import React, { useMemo, useRef, useState } from "react";
import { SWRConfig } from "swr";
import type { Chain } from "viem";

import { onThemeChange } from "@/actions/theme";
import type { onWalletConnect, onWalletDisconnect } from "@/actions/wallet";
import { Web3Provider } from "@/contexts/web3";
import { MarginlyApiContext } from "@/hooks/api";
import { Frame, useCandleApi } from "@/hooks/candles";
import { ConsentSuccessProvider } from "@/partnership/contexts/consent-success/provider";
import type { PoolConfig } from "@/types/core";
import { fallbackExtract } from "@/util/api/api";
import { parse0x } from "@/util/check";
import { FIX_CHAIN_ID, patchChain } from "@/util/viem-ext";

interface ProviderProps {
  serverTime: number;
  apiBaseurl: string;
  apiWs: string;
  chain: Chain;
  children: React.ReactNode;
  disableFeatures?: string[];
  fallback: Record<string, unknown>;
  onWalletConnect?: typeof onWalletConnect;
  onWalletDisconnect?: typeof onWalletDisconnect;
  pools: PoolConfig[];
  projectId: string;
}
export default function Provider({
  apiBaseurl,
  apiWs,
  children,
  chain: _chain,
  disableFeatures,
  fallback,
  onWalletConnect,
  onWalletDisconnect,
  serverTime: _serverTime,
  projectId,
  pools,
}: ProviderProps) {
  const [serverTime] = useState(() => _serverTime);
  const theme = useThemeConfigStore();
  useSetTheme({ theme, onChange: onThemeChange });
  const candleApi = useCandleApi(apiWs);

  const chains = useMemo(
    () => [FIX_CHAIN_ID.includes(_chain.id) ? patchChain(_chain) : _chain],
    [_chain],
  );

  const ready = useRef(false);

  if (!ready.current) {
    ready.current = true;
    // load initial candles from server-generated fallback data
    const candles = fallbackExtract(fallback, "swapCandles");

    for (const [, { points }, match] of candles) {
      const [, _chainId, _address, _frame] = match;
      const chainId = Number(_chainId);
      const address = parse0x(_address);
      const frame: Frame = _frame as any; // todo validate

      const keyObj = {
        chainId: Number(chainId),
        poolAddress: parse0x(address),
        frame,
      };

      candleApi.setCandleValues(
        keyObj,
        // @ts-expect-error
        points,
      );
    }
    // should run only once
  }

  return (
    <MarginlyApiContext.Provider
      value={{ baseurl: apiBaseurl, serverTime, ...candleApi }}
    >
      <Web3Provider
        projectId={projectId}
        chains={chains}
        pools={pools}
        onWalletConnect={onWalletConnect}
        onWalletDisconnect={onWalletDisconnect}
        disableFeatures={disableFeatures}
      >
        <SWRConfig
          value={{
            fallback,
            shouldRetryOnError: false,
            // revalidateOnMount: false,
            // revalidateIfStale: false,
            // keepPreviousData: true,
            // provider: () => new Map(),
          }}
        >
          <ConsentSuccessProvider>{children}</ConsentSuccessProvider>
        </SWRConfig>
      </Web3Provider>
    </MarginlyApiContext.Provider>
  );
}
