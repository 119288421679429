import {
  setValue,
  THEME_CONFIG_VALUES,
  type ThemeConfig,
} from "@marginly/ui/stores/theme";

import Space from "@/components/common/space";
import { capitalize, noop } from "@/util/core";

import { BackButton, ThemeModalHeading, ThemeSelect } from "./styled";
import type { ModalType } from "./types";

interface Props {
  handleSwitchModal: (mt: ModalType, callback?: () => void) => void;
  themeConfig: ThemeConfig;
}
export default function ModalTheme({ themeConfig, handleSwitchModal }: Props) {
  return (
    <>
      <BackButton onClick={handleSwitchModal.bind(null, "common", noop)}>
        <img
          src="/images/back-button.svg"
          alt=""
          style={{ filter: "invert(var(--svg-invert))" }}
        />
      </BackButton>

      <ThemeModalHeading>Choose appearance</ThemeModalHeading>
      <Space height={24} heightMobile={24} />

      {THEME_CONFIG_VALUES.map((value) => (
        <ThemeSelect
          key={value}
          onClick={() => {
            setValue(value, () => {
              handleSwitchModal("common");
            });
          }}
        >
          {value === "auto" ? "System" : capitalize(value)}
          {themeConfig === value && <img src="/images/check.svg" alt="" />}
        </ThemeSelect>
      ))}
    </>
  );
}
