import type { Chain, RpcTransactionReceipt, TransactionReceipt } from "viem";
import {
  defineChain,
  formatLog,
  hexToNumber,
  transactionType,
} from "viem/utils";

import { REALLY_BIGINT } from "@/constants/math";

const statuses = {
  "0x0": "reverted",
  "0x1": "success",
} as const;

function formatTransactionReceipt_unsafe(
  transactionReceipt: Partial<RpcTransactionReceipt>,
) {
  return {
    ...transactionReceipt,
    blockNumber: transactionReceipt.blockNumber
      ? BigInt(transactionReceipt.blockNumber)
      : // fixme zkSync fallback
        REALLY_BIGINT,
    contractAddress: transactionReceipt.contractAddress
      ? transactionReceipt.contractAddress
      : null,
    cumulativeGasUsed: transactionReceipt.cumulativeGasUsed
      ? BigInt(transactionReceipt.cumulativeGasUsed)
      : null,
    effectiveGasPrice: transactionReceipt.effectiveGasPrice
      ? BigInt(transactionReceipt.effectiveGasPrice)
      : null,
    gasUsed: transactionReceipt.gasUsed
      ? BigInt(transactionReceipt.gasUsed)
      : null,
    logs: transactionReceipt.logs
      ? transactionReceipt.logs.map((log) => formatLog(log))
      : null,
    to: transactionReceipt.to ? transactionReceipt.to : null,
    transactionIndex: transactionReceipt.transactionIndex
      ? hexToNumber(transactionReceipt.transactionIndex)
      : null,
    status: transactionReceipt.status
      ? statuses[transactionReceipt.status]
      : null,
    type: transactionReceipt.type
      ? transactionType[
          transactionReceipt.type as keyof typeof transactionType
        ] || transactionReceipt.type
      : null,
  } as TransactionReceipt;
}

const FORMATTER = {
  format: formatTransactionReceipt_unsafe,
  type: "transactionReceipt",
} as const;

export const FIX_CHAIN_ID: number[] = [280 /* zkSync testnet */];

/** @deprecated possible fix of BigInt comparison issue */
export const patchChain = <C extends Chain>(chain: C) => {
  // TODO: fixme
  // @ts-ignore
  return defineChain(chain, { formatters: { transactionReceipt: FORMATTER } });
};
