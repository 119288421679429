"use client";
import { useRouter } from "next/navigation";
import { useMemo } from "react";
import { t } from "ttag";

import { CHAINKEY_MAP, SUPPORTED_CHAINS } from "@/constants/chains";

import { ActionButton, Description, Icon, Title, Wrapper } from "./styled";

interface UnsupportedChainProps {
  defaultChain: string;
  chainName: string;
}

type Chain_ =
  | (typeof SUPPORTED_CHAINS)[keyof typeof SUPPORTED_CHAINS]
  | undefined;

export default function UnsupportedChain(props: UnsupportedChainProps) {
  const router = useRouter();

  const chain: Chain_ =
    SUPPORTED_CHAINS[
      props.chainName as unknown as keyof typeof SUPPORTED_CHAINS
    ];

  const targetChain: NonNullable<Chain_> =
    SUPPORTED_CHAINS[
      props.defaultChain as unknown as keyof typeof SUPPORTED_CHAINS
    ];

  const placeholder = useMemo(() => {
    if (chain?.name.toLowerCase().includes("polygon")) {
      return "/images/polygon-placeholder.png";
    }

    if (chain?.name.toLowerCase().includes("zksync")) {
      return "/images/zksync-placeholder.png";
    }

    if (chain?.name.toLowerCase().includes("eth")) {
      return "/images/eth-placeholder.png";
    }

    return "/images/unknown-placeholder.png";
  }, [chain]);

  const title = chain?.name;

  function switchNetwork() {
    router.push(`/${CHAINKEY_MAP[targetChain.id]}`);
  }

  return (
    <Wrapper>
      <Icon src={placeholder} alt="" />
      <Title>
        {title ? <>{title} is&nbsp;coming&nbsp;soon</> : t`Unknown network`}
      </Title>
      <Description>
        Select {targetChain.name} network to start using Marginly app.
      </Description>
      <ActionButton onClick={switchNetwork}>Change Network</ActionButton>
    </Wrapper>
  );
}
