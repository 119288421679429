import Noop from "@marginly/ui/components/helpers/noop";
import Toggle from "@marginly/ui/components/toggle/toggle";
import T from "@marginly/ui/components/typography";
import cn from "classnames";
import { useRouter } from "next/navigation";
import { useState } from "react";
import type { Chain } from "viem";

import Space from "@/components/common/space";
import { useNetworks } from "@/components/navigation/wallet-modal/hooks";
import { getNetworkIcon } from "@/util/common";
import { noop } from "@/util/core";

import { Network } from "../styled";
import { BackButton, TestNetworks, ThemeModalHeading } from "./styled";
import { ModalType } from "./types";

interface Props {
  chain: Chain;
  chains: Record<string, Chain>;
  handleSwitchModal: (mt: ModalType, callback?: () => void) => void;
}

export default function ModalNetwork({
  chain,
  chains,
  handleSwitchModal,
}: Props) {
  const router = useRouter();
  const [isTestNetworks, setIsTestNotworks] = useState(Boolean(chain.testnet));
  const [mainnets, testnets] = useNetworks(chains);

  return (
    <div>
      <BackButton onClick={handleSwitchModal.bind(null, "common", noop)}>
        <img
          src="/images/back-button.svg"
          alt=""
          style={{ filter: "invert(var(--svg-invert))" }}
        />
      </BackButton>
      <ThemeModalHeading>Choose network</ThemeModalHeading>
      <Space height={16} heightMobile={16} />

      {mainnets?.map(({ key: name, ..._chain }) => (
        <Network
          key={_chain.id}
          className={chain.id === _chain.id ? "selected" : ""}
          onClick={() => {
            handleSwitchModal("common", () => router.push(`/${name}`));
          }}
        >
          <div
            className="icon"
            style={{ backgroundColor: "var(--fill-accent-primary)" }}
          >
            <img src={getNetworkIcon(_chain.id)} alt="" />
          </div>
          <div className="title">{_chain.name}</div>
          <div className="checked">
            <img
              src="/images/network-checked.svg"
              alt=""
              style={{ filter: "invert(var(--svg-invert))" }}
            />
          </div>
        </Network>
      ))}

      {/* {(!!testnets?.length || isTestNetworks) && (
        <>
          <Space height={16} heightMobile={16} />
          <TestNetworks onClick={() => setIsTestNotworks(!isTestNetworks)}>
            <T body>Test networks</T>
            <Toggle className={cn({ on: isTestNetworks })} />
          </TestNetworks>
        </>
      )}

      {isTestNetworks ? <Space height={16} heightMobile={16} /> : <Noop />}
      {testnets?.map(({ key: name, ..._chain }) => (
        <Network
          key={_chain.id}
          className={cn({
            selected: chain.id === _chain.id,
            visible: isTestNetworks,
            test: true,
          })}
          onClick={() => {
            handleSwitchModal("common", () => router.push(`/${name}`));
          }}
        >
          <div className="icon">
            <img src={getNetworkIcon(_chain.id)} alt="" />
          </div>
          <div className="title">{_chain.name}</div>
          <div className="checked">
            <img
              src="/images/network-checked.svg"
              alt=""
              style={{ filter: "invert(var(--svg-invert))" }}
            />
          </div>
        </Network>
      ))} */}
    </div>
  );
}
