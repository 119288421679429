import Button from "@marginly/ui/components/button";
import Noop from "@marginly/ui/components/helpers/noop";
import Thumbnail from "@marginly/ui/components/thumbnail";
import T from "@marginly/ui/components/typography";
import cn from "classnames";
import { useRouter } from "next/navigation";
import { useAccount, useChainId, useWalletClient } from "wagmi";

import { TokenImage } from "@/components/common/token";
import { CHAINKEY_MAP } from "@/constants/chains";
import { useBalanceNormalize, useToken } from "@/hooks/token";
import { formatCoin } from "@/util/format-coin";
import { normalizePtToken } from "@/util/common";
import { watchAssetRequest } from "@/util/wallet";

import { AssetItem } from "./styled";

const B0 = BigInt(0);

export default function Balance({
  address,
  chainName,
  trade,
  unwrap,
}: {
  address?: `0x${string}`;
  chainName?: string;
  trade?: () => void;
  unwrap?: boolean;
}) {
  const walletClient = useWalletClient();
  const account = useAccount();
  const router = useRouter();

  const balance = useBalanceNormalize({
    address: account?.address,
    token: address,
  });

  const amount = formatCoin(balance.data?.formatted);

  const symbol = balance.data?.symbol;
  const decimals = balance.data?.decimals;

  async function addToken() {
    const provider = walletClient.data;
    if (!provider) {
      console.error("No provider");
    } else if (!address || !symbol || !decimals) {
      console.error("no token data");
    } else {
      provider.request(
        watchAssetRequest({
          address,
          symbol,
          decimals,
          image: "",
        }),
      );
    }
  }

  return balance.data && (balance.data.value > B0 || !address) ? (
    <AssetItem>
      <TokenImage token={symbol} onClick={addToken} rectangle />
      <T body>
        {amount}&nbsp;{normalizePtToken(symbol)}
      </T>
      {unwrap ? (
        <Button
          secondary
          sm
          className="button"
          onClick={() => router.push(`/${chainName}/swap?in=${address}&out=`)}
        >
          <T action>Unwrap</T>
        </Button>
      ) : trade &&
        symbol !== "ETH" &&
        balance.data &&
        balance.data?.value > B0 ? (
        <Button secondary sm className="button" onClick={trade}>
          <T action>Trade</T>
        </Button>
      ) : (
        <Noop />
      )}
    </AssetItem>
  ) : (
    <Noop />
  );
}

export function RestBalance({
  address,
  symbol,
  amount,
  logo,
}: {
  address: `0x${string}`;
  symbol: string;
  amount: number;
  logo: string;
  usd: number;
}) {
  const chainId = useChainId();
  const chainName = CHAINKEY_MAP[chainId];
  const router = useRouter();
  const token = useToken({ address, enabled: true });
  const decimals = token?.decimals;

  const walletClient = useWalletClient();

  async function addToken() {
    const provider = walletClient.data;

    if (!provider) {
      console.error("No provider");
    } else if (!address || !symbol || !decimals) {
      console.error("no token data");
    } else {
      provider.request(
        watchAssetRequest({
          address,
          symbol,
          decimals,
          image: logo,
        }),
      );
    }
  }

  function swap() {
    router.push(`/${chainName}/swap?in=${address}`);
  }

  return (
    <AssetItem>
      <Thumbnail
        className={cn({ thumbnail: true, resticon: true })}
        style={{ position: "relative" }}
        onClick={addToken}
      >
        <img src={logo} alt={symbol} />
      </Thumbnail>
      <T body>
        {formatCoin(amount)}&nbsp;{symbol}
      </T>
      <Button secondary sm className="button" onClick={swap}>
        <T action>Swap</T>
      </Button>
    </AssetItem>
  );
}
