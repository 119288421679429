import Noop from "@marginly/ui/components/helpers/noop";
import { useThemeConfigStore } from "@marginly/ui/stores/theme";
import cn from "classnames";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSwipeable } from "react-swipeable";
import { Chain } from "viem";
import { useAccount } from "wagmi";

import Portal from "@/components/common/portal";
import { SWIPEABLE_CONFIG } from "@/constants/common";
import { useWeb3Context } from "@/contexts/web3";
import { useMarginlyApi } from "@/hooks/api";
import { useClickOutside } from "@/hooks/common";
import { is0X, parse0x, parseByPredicate } from "@/util/check";
import { markWritable } from "@/util/core";

import ModalCommon from "./common";
import ModalNetwork from "./network";
import { ModalBody, ModalWrapper, SwipeableWrapper } from "./styled";
import ModalTheme from "./theme";
import ModalTrade from "./trade";
import type { ModalType } from "./types";

interface Props {
  onHide?: () => void;
  chain: Chain;
  chains: Record<string, Chain>;
}

const useBounce = (_default?: boolean, ms: number = 300) => {
  const defaultRef = useRef(Boolean(_default));
  const [v, set] = useState(defaultRef.current);

  useEffect(() => {
    let cancel = false;

    if (v !== defaultRef.current) {
      setTimeout(() => {
        if (!cancel) {
          set(defaultRef.current);
        }
      }, ms);
    }

    return () => {
      cancel = true;
    };
  }, [v, ms]);

  const bounce = useCallback(
    (cb: () => void) => {
      set(!defaultRef.current);
      setTimeout(cb, ms / 2);
    },
    [ms],
  );

  return { bounce, value: v };
};

const parseToken = parseByPredicate(is0X);

const rgbToHex = (str: string) =>
  "#" +
  str
    .split(";")
    .map(Number)
    .map((n) => n.toString(16).padStart(2, "0"))
    .join("");

export const useImgUtil = (ref: RefObject<HTMLCanvasElement>) => {
  // TODO move cache to upper level
  const cache = useRef<Record<string, string>>({});

  async function loadImage(src: string) {
    let resolve: undefined | ((img: HTMLImageElement) => void);
    const promise = new Promise<HTMLImageElement>((r) => {
      resolve = r;
    });

    const img = new Image(24, 24);

    img.onload = () => {
      resolve?.(img);
    };

    img.crossOrigin = "Anonymous";
    img.src = src;
    return promise;
  }

  async function getCommonColor(src: string) {
    if (cache.current[src]) {
      return cache.current[src];
    }

    const img = await loadImage(src);
    const ctx = ref.current?.getContext("2d");

    if (!ctx) {
      return;
    }

    ctx.drawImage(img, 0, 0, 24, 24);
    const count: Record<string, number> = {};
    const max: [string, number] = ["0;0;0", -Infinity];

    // has 24x24 passes, so needs to be cached
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 24; j++) {
        const [r, g, b] = ctx.getImageData(i, j, 1, 1).data;

        if (!r && !g && !b) {
          continue;
        }

        const key = `${r};${g};${b}`;
        const val = (count[key] ?? 0) + 1;
        count[key] = val;

        if (val > max[1]) {
          max[0] = key;
          max[1] = val;
        }
      }
    }

    const hex = rgbToHex(max[0]);
    cache.current[src] = hex;
    return hex;
  }

  return getCommonColor;
};

function handleSwap(onHide?: () => void) {
  return typeof window !== undefined
    ? () => {
        document.getElementById("swiper")?.classList.add("hidden");
        setTimeout(() => {
          document.body.style.overflow = "visible";
          onHide?.();
        }, 300);
      }
    : undefined;
}

const WalletModal = ({ onHide, chain, chains }: Props) => {
  const themeConfig = useThemeConfigStore();
  const [modalType, setModalType] = useState<ModalType>("common");
  const ref = useRef(null);
  const { bounce } = useBounce(false, 600);

  const swiper = useSwipeable({
    onSwipedDown: handleSwap(onHide),
    ...SWIPEABLE_CONFIG,
  });

  useClickOutside(ref, () => {
    document.body.style.overflow = "visible";
    onHide?.();
  });

  const handleSwitchModal = useCallback(
    (mt: ModalType, callback?: () => void) => {
      bounce(() => {
        setModalType(mt);
        callback?.();
      });
    },
    [bounce],
  );

  const tradeToken = parseToken(modalType);
  const visible = true;
  const { pools, internalChainId } = useWeb3Context();
  const account = useAccount();

  const dArgs = useMemo(
    () =>
      internalChainId && account.address
        ? markWritable([
            internalChainId,
            account.address,
            pools.map(({ address }) => address),
          ] as const)
        : undefined,
    [internalChainId, account.address, pools],
  );

  const { data: details } = useMarginlyApi({
    enabled: Boolean(dArgs),
    endpoint: "positionDetailsMulti",
    args: dArgs!,
  });

  const detailsMap = useMemo(() => {
    const map: Record<`0x${string}`, string> = {};
    for (const { positionType, marginlyPool } of details ?? []) {
      map[marginlyPool] = positionType;
    }
    return map;
  }, [details]);

  const poolByToken: Record<`0x${string}`, `0x${string}`[]> = useMemo(() => {
    const result: Record<`0x${string}`, `0x${string}`[]> = {};
    for (const { address, baseToken, quoteToken } of pools ?? []) {
      const positionType = detailsMap[address];
      if (positionType === "Long" || positionType === "Short") {
        const base = baseToken ? parse0x(baseToken.toLowerCase()) : undefined;
        const quote = quoteToken
          ? parse0x(quoteToken.toLowerCase())
          : undefined;
        if (base) {
          result[base] = result[base]
            ? [...(result[base] || []), address]
            : [address];
        }
        if (quote) {
          result[quote] = result[quote]
            ? [...(result[quote] || []), address]
            : [address];
        }
      }
    }
    return result;
  }, [detailsMap, pools]);

  return (
    <Portal>
      <SwipeableWrapper>
        {visible ? (
          <div {...swiper} id="swiper">
            <ModalWrapper
              onClick={onHide}
              ref={ref}
              className={cn({
                visible: visible,
              })}
            >
              <ModalBody
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                }}
                className={cn({
                  visible: visible,
                })}
              >
                <div>
                  {modalType === "theme" ? (
                    <ModalTheme
                      handleSwitchModal={handleSwitchModal}
                      themeConfig={themeConfig}
                    />
                  ) : modalType === "common" ? (
                    <ModalCommon
                      chain={chain}
                      chains={chains}
                      onHide={onHide}
                      handleSwitchModal={handleSwitchModal}
                      themeConfig={themeConfig}
                      poolByToken={poolByToken}
                    />
                  ) : modalType === "network" ? (
                    <ModalNetwork
                      chain={chain}
                      chains={chains}
                      handleSwitchModal={handleSwitchModal}
                    />
                  ) : tradeToken ? (
                    <ModalTrade
                      address={tradeToken}
                      chain={chain}
                      handleSwitchModal={handleSwitchModal}
                      onHide={onHide}
                    />
                  ) : (
                    <Noop />
                  )}
                </div>
              </ModalBody>
            </ModalWrapper>
          </div>
        ) : (
          <Noop />
        )}
      </SwipeableWrapper>
    </Portal>
  );
};

export default WalletModal;
