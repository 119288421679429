import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px 20px;
  height: calc(100vh - 50px);
  box-sizing: border-box;
  transform: translateY(20px);
`;

export const Title = styled.div`
  color: var(--text-primary);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-variation-settings: "wght" 700;
  line-height: 32px;
  letter-spacing: 0.48px;
  margin-bottom: 24px;
  @media (min-width: 1024px) {
    font-variation-settings: "wght" 600;
  }
`;

export const Icon = styled.img`
  margin-bottom: 40px;
  width: 128px;
  height: 128px;
  object-fit: contain;
  object-position: center;
`;

export const Description = styled.div`
  color: var(--text-secondary);
  text-align: center;

  /* Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  margin-bottom: 48px;
`;

export const ActionButton = styled.button`
  background: var(--fill-primary);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px #33140014;
  box-shadow: 0px 2px 1px 0px #3314000a;
  width: 100%;
  max-width: 234px;
  font-variation-settings: "wght" 700;
  height: 64px;
  letter-spacing: 0.03em;
  color: var(--text-invert-primary);
  // margin: auto auto 0;
  width: 100%;
  border: 0;
  outline: 0;
  @media (min-width: 1024px) {
    width: calc(100% - 16px);
    display: block;
  }
`;
