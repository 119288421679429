"use client";

import { useEffect } from "react";

import { onNetworkChange } from "@/actions/wallet";
import { isFiniteNumber } from "@/util/check";

interface Props {
  chainId?: number;
}

export default function ClientHandler(props: Props) {
  useEffect(() => {
    if (isFiniteNumber(props.chainId)) {
      onNetworkChange(props.chainId);
    }
  }, [props.chainId]);

  return null;
}
