"use client";

import cn from "classnames";
import { usePathname } from "next/navigation";
import { Fragment } from "react";
import { Chain } from "viem";

import { CHAINKEY_MAP } from "@/constants/chains";

import { Link, LinksRow, LinkText, SubNavigation } from "./styled";

interface Props {
  chain: Chain;
}

const LINKS = [
  { link: "/", icon: `/images/new-navigation/home.svg`, text: `Home` },
  { link: "/earn", icon: `/images/new-navigation/deposit.svg`, text: `Earn` },
  { link: "/farm", icon: `/images/new-navigation/farm.svg`, text: `Farm` },
  {
    link: "/all-trades",
    icon: `/images/new-navigation/candles.svg`,
    text: `Trade`,
  },
  { link: "/swap", icon: `/images/new-navigation/swap.svg`, text: `Swap` },
];

const BottomNavigation = ({ chain }: Props) => {
  const pathname = usePathname();
  const chainName = CHAINKEY_MAP[chain.id];

  if (
    pathname.includes("/trade/") ||
    pathname.includes("/swap") ||
    pathname.includes("/watch-position/") ||
    pathname.includes("/withdraw/") ||
    pathname.includes("/lend-info") ||
    pathname.includes("/increase-leverage/") ||
    pathname.includes("/deposit/") ||
    pathname.includes("/history") ||
    pathname.includes("-success") ||
    pathname.includes("/blast-reward")
  ) {
    return <div></div>;
  }

  const isTestNetworks = Boolean(chain.testnet);

  return (
    <SubNavigation>
      <LinksRow>
        {LINKS.map((item, key) => (
          <Fragment key={key}>
            {isTestNetworks && item.text == "Swap" ? (
              <Fragment />
            ) : (
              <Link
                href={item.link == `/` ? `/` : `/` + chainName + item.link}
                className={cn({
                  active:
                    `/` + chainName + item.link == pathname ||
                    (item.link == `/` && pathname == `/` + chainName),
                })}
              >
                <img
                  src={item.icon}
                  alt=""
                  className="pic"
                  style={{ filter: "invert(var(--svg-invert))" }}
                />
                <LinkText>{item.text}</LinkText>
              </Link>
            )}
          </Fragment>
        ))}
      </LinksRow>
    </SubNavigation>
  );
};

export default BottomNavigation;
