import { createContext } from "react";

import { Callback } from "./types";

interface ConsentSuccessContextModel {
  callback: Callback;
  setCallback: (callback: Callback) => void;
}

export const ConsentSuccessContext = createContext<ConsentSuccessContextModel>(
  {} as ConsentSuccessContextModel,
);
