import styled from "styled-components";

export const TradeBlock = styled.div`
  background: var(--fill-negative-bg);
  color: var(--text-primary);
  border-radius: 24px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  height: 200px;
  // min-width: calc(100vw / 2 - 52px);
  min-width: calc(100vw / 2 - 8px);
  transition: 200ms ease-out;

  &:hover {
    background: rgba(240, 165, 204, 0.24);
  }
  .trade {
    &__heading {
      display: flex;
      align-items: center;
      gap: 4px;
      p,
      div {
        font-variation-settings: "wght" 600;
        margin: 0;
        color: var(--text-primary);
      }
    }

    &__bottom {
      font-variation-settings: "wght" 600;
      letter-spacing: 0.02em;
      padding: 4px;
      line-height: 20px;
    }
    &__percents {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: var(--text-negative);
      font-variation-settings: "wght" 500;
    }
    &__badge {
      position: absolute;
      right: 12px;
      top: 12px;

      display: flex;
      height: 24px;
      min-width: 24px;
      padding: var(--spacing-space-4, 4px) var(--spacing-space-8, 8px);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-space-4, 4px);

      border-radius: var(--rounding-radius-m, 16px);
      backdrop-filter: blur(12px);
      -webkit-backdrop-filter: blur(12px);

      &--positive {
        background: var(--fill-positive-bg, rgba(165, 217, 139, 0.16));
        color: var(--text-positive, #45be33);
      }
      &--negative {
        background: var(--fill-negative-bg, rgba(240, 165, 204, 0.16));
        color: var(--text-negative, #e54796);
      }
    }
  }

  &.up {
    background: var(--fill-positive-bg);
    &:hover {
      background: rgba(165, 217, 139, 0.24);
    }
    .trade__percents {
      color: var(--text-positive);
    }
  }
  @media (min-width: 768px) {
    // min-width: 125px;
    min-width: 188px;
  }

  .wallet-graph {
    height: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 64px;
    max-height: 64px;
    margin: 0 -12px 0 -12px;
    width: calc(100% + 24px);
    height: 82px;
  }

  &.awaiting,
  &.awaiting:hover {
    background: var(--fill-secondary, rgba(204, 187, 184, 0.16));

    .trade__bottom {
      position: relative;
      .lend-button {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .awaiting__buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    button {
      width: 100%;
    }
  }
  .graph {
    width: 100%;
  }
`;
