import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { interval, map, ReplaySubject, Subscription } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";

import { FRAME_TO_SECONDS } from "@/constants/chart";
import { AVLTree } from "@/util/avl/tree";
import { hasKey } from "@/util/check";
import { getSeconds, hasPrefix, stripPrefix } from "@/util/core";

interface IResponseError {
  code: number;
  message: string;
}

interface IResponse<T> {
  requestId: string;
  error: IResponseError | null;
  result: T | null;
}

export interface ICandle {
  openTimestamp: number;
  frame: Frame;

  baseCurrency: string;
  quoteCurrency: string;

  // Prices:
  open: string;
  high: string;
  low: string;
  close: string;

  volumeBuy: number;
  volumeSell: number;
}

export type Frame =
  | "OneMinute"
  | "ThreeMinute"
  | "FiveMinute"
  | "FifteenMinute"
  | "ThirtyMinute"
  | "OneHour"
  | "FourHour"
  | "EightHour"
  | "OneDay"
  | "OneWeek"
  | "OneMonth";

interface CandleResult {
  chainId: number;
  poolAddress: `0x${string}`;
  frame: Frame;
  period: [number, number];
  points: Array<[number, ICandle]>;
}

interface ISubscribeCandlesRequest {
  type: "SubscribeNewSwapCandles";
  requestId: string;
  chainId: number;
  poolAddress: `0x${string}`;
  frame: Frame;
}

interface IUnsubscribeCandlesRequest {
  type: "UnsubscribeNewSwapCandles";
  requestId: string;
  subscribeRequestId: string;
}

interface IGetRangeRequest {
  type: "GetSwapCandlesRange";
  requestId: string;
  chainId: number;
  poolAddress: `0x${string}`;
  frame: Frame;
  fromUnixS: number;
  toUnixS: number;
}

interface IPingRequest {
  type: "Ping";
  requestId: string;
}

type ApiRequests =
  | ISubscribeCandlesRequest
  | IUnsubscribeCandlesRequest
  | IGetRangeRequest
  | IPingRequest;

interface ApiResponseMap extends Record<string, unknown> {
  SubscribeNewSwapCandles: CandleResult;
  GetSwapCandlesRange: CandleResult;
  Ping: null;
  UnsubscribeNewSwapCandles: null;
}

type ApiRequestType = ApiRequests["type"];
type SubRequestType = Extract<ApiRequestType, `Subscribe${string}`>;
type UnsubRequestType = Extract<ApiRequestType, `Unsubscribe${string}`>;
type ReadRequestType = Exclude<ApiRequestType, SubRequestType>;
type ApiRequest<T extends ApiRequestType> = Extract<ApiRequests, { type: T }>;

type UserRequest<T extends ApiRequestType> = Omit<
  ApiRequest<T>,
  "requestId" | "type"
>;

const KEEPALIVE_INTERVAL = 10000;
const RETRY_TIMEOUT = 2000;

const areEqualWithin = (a: number, b: number, epsilon: number) =>
  Math.abs(a - b) < epsilon;

const isSubscribe = hasPrefix("Subscribe");
const stripSubscribe = stripPrefix("Subscribe");

const getUnsubscribeMethod = <T extends string>(
  method: `Subscribe${T}`,
): `Unsubscribe${T}` => {
  const name = stripSubscribe(method);
  return `Unsubscribe${name}`;
};

const isResponse = (message: unknown): message is IResponse<any> => {
  if (!message || typeof message !== "object") {
    return false;
  }

  return ["requestId", "error", "result"].every((key) => hasKey(key, message));
};

class CandleWsApi {
  #id = Math.floor(Math.random() * Math.pow(2, 64))
    .toString(16)
    .padStart(16, "0");

  #reconnect = true;
  #url: string;
  #api$: WebSocketSubject<unknown> | undefined;
  #apiSub: Subscription | undefined;
  #apiKeepalive: Subscription | undefined;
  #reqCount = 0;
  #requests = new Map<string, ApiRequests>();
  #resolvers = new Map<string, (response: IResponse<any>) => void>();
  #subs = new Map<string, [string, ReplaySubject<IResponse<any>>]>();

  #getRequestId = () => {
    return `req:${this.#id}:${this.#reqCount++}`;
  };

  #getMessage = (message: unknown) => {
    if (!isResponse(message)) {
      console.error("unexpected ws message: ", message);
      return;
    }

    const { requestId } = message;
    const _request = this.#requests.get(requestId);

    if (!_request) {
      throw new Error(`no request for requestId: ${requestId}`);
    }

    if (isSubscribe(_request?.type)) {
      const request: ApiRequest<typeof _request.type> = _request as any;
      const { requestId: _requestId, ...req } = request;
      const key = JSON.stringify(req);
      const sub = this.#subs.get(key);

      if (!sub) {
        throw new Error(
          `no subscription found for key: ${key}, request: ${_requestId}`,
          { cause: { key, request } },
        );
      }

      if (sub[0] !== _requestId) {
        throw new Error(
          `subscription requestId mismatch: ${sub[0]}, request: ${_requestId}`,
          { cause: { key, request } },
        );
      }

      if (sub[1].closed) {
        throw new Error(
          `subscription is closed: ${key}, request: ${_requestId}`,
          { cause: { key, request } },
        );
      }

      sub[1].next(message);
      return;
    }

    const resolve = this.#resolvers.get(requestId);

    if (!resolve) {
      if (_request.type !== "Ping") {
        console.warn("no resolver for requestId: " + requestId, {
          request: _request,
          message,
        });
      }
    } else {
      resolve(message);
      this.#resolvers.delete(requestId);
    }
  };

  #createRequest = <T extends ApiRequestType>(
    _type: ApiRequestType,
    req: UserRequest<T>,
  ) => {
    const request = {
      type: _type,
      requestId: this.#getRequestId(),
      ...req,
    };

    this.#requests.set(request.requestId, request as ApiRequests);
    return request;
  };

  // more like reinit
  #init = () => {
    console.warn("init ws");
    const subscribtionRequests: ApiRequests[] = [];

    for (const [, [key]] of this.#subs) {
      const request = this.#requests.get(key);

      if (!request) {
        continue;
      }

      subscribtionRequests.push(request);
      // value.complete();
      // this.#subjects.delete(key);
    }

    const api$ = webSocket(this.#url);

    this.#apiSub = api$.subscribe({
      next: this.#getMessage,
      error: (err) => {
        console.error("ws error:", err);
        this.close(true);

        console.warn(`reconnecting in ${RETRY_TIMEOUT}ms...`);
        setTimeout(this.#init, RETRY_TIMEOUT);
      },
      complete: () => {
        console.warn("ws closed");
        this.#apiKeepalive?.unsubscribe();
        this.#apiKeepalive = undefined;

        if (this.#reconnect) {
          console.warn(`reconnecting in ${RETRY_TIMEOUT}ms...`);
          setTimeout(this.#init, RETRY_TIMEOUT);
        }
      },
    });

    // restore subscriptions on reconnect

    for (const _request of subscribtionRequests) {
      if (!_request || !isSubscribe(_request.type)) {
        throw new Error("invalid request", { cause: { request: _request } });
      }

      api$.next(_request);
    }

    this.#apiKeepalive = interval(KEEPALIVE_INTERVAL)
      .pipe(map(() => this.#createRequest("Ping", {})))
      .subscribe(api$);

    this.#api$ = api$;
  };

  constructor(url: string) {
    console.warn("creating ws");
    this.#url = url;
    this.#init();
  }

  close(reconnect?: boolean) {
    console.warn("closing ws");
    this.#reconnect = Boolean(reconnect);
    this.#api$?.complete();
    this.#apiSub?.unsubscribe();
    this.#requests.clear();
    this.#subs.clear();
    this.#resolvers.clear();
  }

  request = <T extends ReadRequestType>(_type: T, req: UserRequest<T>) => {
    if (!this.#api$) {
      throw new Error("api not initialized");
    }

    const request = this.#createRequest(_type, req);

    const promise = new Promise<IResponse<any>>((resolve) => {
      this.#resolvers.set(request.requestId, resolve);
    });

    this.#api$.next(request);
    return promise;
  };

  #subscribe = <T extends SubRequestType>(_type: T, req: UserRequest<T>) => {
    if (!this.#api$) {
      throw new Error("api not initialized");
    }

    const unsubType: UnsubRequestType = getUnsubscribeMethod(_type);
    const subKey = JSON.stringify({ type: _type, ...req });
    let sub = this.#subs.get(subKey);

    if (!sub) {
      const subscription = this.#createRequest(_type, req);
      const { requestId } = subscription;
      this.#requests.set(requestId, subscription as ApiRequests);
      sub = [requestId, new ReplaySubject<IResponse<any>>(1)];
      this.#subs.set(subKey, sub);
      this.#api$.next(subscription);
    }

    const [requestId, subject] = sub;

    const unsubscribe = () => {
      if (!this.#subs.has(subKey)) {
        return;
      }

      this.#subs.delete(subKey);

      return this.request(unsubType, {
        subscribeRequestId: requestId,
      });
    };

    return { unsubscribe, observable$: subject.asObservable() };
  };

  subscribe = <T extends SubRequestType>(
    _type: T,
    req: UserRequest<T>,
    callback: (response: IResponse<ApiResponseMap[T]>) => void,
  ) => {
    const { unsubscribe, observable$ } = this.#subscribe(_type, req);
    const sub = observable$.subscribe(callback);

    return () => {
      sub.unsubscribe();
      return unsubscribe();
    };
  };
}

type ChartKeyObj = UserRequest<"SubscribeNewSwapCandles">;

const getChartKey = ({ chainId, poolAddress, frame }: ChartKeyObj) =>
  `${chainId}:${poolAddress}:${frame}`;

const getTree = (
  refObject: RefObject<Record<string, AVLTree<number, ICandle>>>,
  keyObj: ChartKeyObj,
) => {
  const key = getChartKey(keyObj);

  if (!refObject.current) {
    throw new Error("refObject is not initialized");
  }

  if (!refObject.current?.[key]) {
    refObject.current[key] = new AVLTree<number, ICandle>();
  }

  return refObject.current[key];
};

const wrapAsyncReturnFn = <T>(
  pUnsub: Promise<() => Promise<T> | undefined>,
) => {
  let resolve: (() => void) | undefined;
  const promise = new Promise<void>((r) => (resolve = r));
  let unsub: () => Promise<T> | undefined;

  pUnsub.then((fn) => {
    unsub = fn;
    resolve?.();
  });

  return () => promise.then(() => unsub?.());
};

export const useCandleApi = (url: string) => {
  const resolve = useRef<((api: CandleWsApi) => void) | null>(null);

  const promise = useRef(
    new Promise<CandleWsApi>((res) => (resolve.current = res)),
  );

  const [api, setApi] = useState<CandleWsApi | undefined>();
  const avlRef = useRef<Record<string, AVLTree<number, ICandle>>>({});

  const waitFor = useCallback(async () => {
    if (!api) {
      return await promise.current;
    }

    return api;
  }, [api]);

  // do not use outside
  const _wfRef = useRef(waitFor);
  _wfRef.current = waitFor;

  const getValues = useCallback(
    /**
     * @param _from in sec
     * @param _to in sec
     * */
    async (keyObj: ChartKeyObj, _from: number, _to?: number) => {
      const adjust = FRAME_TO_SECONDS[keyObj.frame];
      let refetch = !_to;
      const from = Math.floor(_from / adjust) * adjust; // round by timeframe
      const to = Math.floor((_to ?? getSeconds()) / adjust) * adjust;
      const tree = getTree(avlRef, keyObj);

      if (!refetch) {
        const min = tree?.minKey();
        const max = tree?.maxKey();

        if (!min || !max) {
          refetch = true;
        } else {
          refetch =
            !areEqualWithin(from, min, adjust / 10) ||
            !areEqualWithin(to, max, adjust / 10);
        }
      }

      if (!refetch) {
        const result: [number, ICandle][] = [];

        tree?.range(from, to, (candle, time) => {
          result.push([time, candle]);
        });

        return result;
      } else {
        const _api = await _wfRef.current();

        const result: IResponse<CandleResult> = await _api.request(
          "GetSwapCandlesRange",
          {
            ...keyObj,
            fromUnixS: from,
            toUnixS: to,
          },
        );

        if (result.error || !result.result) {
          const code = result.error?.code ?? -Infinity;
          const message = result.error?.message ?? "unknown error";
          throw new Error(message, { cause: { code } });
        }

        const candles = result.result.points;

        for (let i = 0; i < candles.length; ++i) {
          const [time, candle] = candles[i] as [number, ICandle];

          /*
          if (i === candles.length - 1) {
            console.log("last", time, new Date(time * 1000));
          }
          */

          tree?.set(time, candle);
        }

        return candles;
      }
    },
    [],
  );

  const watch = useCallback(
    (_keyObj: ChartKeyObj, _callback: (result: CandleResult) => void) => {
      const fn = async (
        keyObj: ChartKeyObj,
        callback: (result: CandleResult) => void,
      ) => {
        const _api = await _wfRef.current();

        return _api.subscribe("SubscribeNewSwapCandles", keyObj, (response) => {
          if (response.error) {
            console.error("error in response", response);
            return;
          }

          if (!response.result) {
            console.error("empty result", response);
            return;
          }

          const tree = getTree(avlRef, keyObj);

          for (const [time, candle] of response.result.points) {
            // const { high, low, open, close } = candle;

            /*
            console.log("watch", time, new Date(time * 1000), {
              high,
              low,
              open,
              close,
            });
            */

            tree?.set(time, candle);
          }

          callback(response.result);
        });
      };

      const p = fn(_keyObj, _callback);
      return wrapAsyncReturnFn(p);
    },
    [],
  );

  const setCandleValues = useCallback(
    (keyObj: ChartKeyObj, candles: [number, ICandle][]) => {
      const tree = getTree(avlRef, keyObj);

      for (const [time, candle] of candles) {
        tree?.set(time, candle);
      }
    },
    [],
  );

  useEffect(() => {
    const api = new CandleWsApi(url);
    setApi(api);
    resolve.current?.(api);

    return () => {
      api.close();
      resolve.current = null;
      promise.current = new Promise<CandleWsApi>(
        (res) => (resolve.current = res),
      );
    };
  }, [url]);

  const _getTree = useCallback(
    (keyObj: ChartKeyObj) => getTree(avlRef, keyObj),
    [],
  );

  return {
    api,
    getTree: _getTree,
    getTrees: () => avlRef.current,
    setCandleValues,
    getValues,
    watch,
    waitFor,
  };
};
