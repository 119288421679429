"use client";
import Button from "@marginly/ui/components/button";
import T from "@marginly/ui/components/typography";
import { ConsentModal } from "@marginly/ui/features/terms-and-conditions/consent-modal";
import cn from "classnames";
import NextLink from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect } from "react";
import { Chain } from "viem";
import { useAccount, useChainId, useDisconnect, useSignMessage } from "wagmi";

import { CHAINKEY_MAP } from "@/constants/chains";
import { useDexPartner } from "@/contexts/partner";
import { useRx } from "@/contexts/rx";
import { useBaseUrl, useMarginlyApi } from "@/hooks/api";
import { useStateFrom } from "@/hooks/rx";
import { useConsentSuccess } from "@/partnership/contexts/consent-success/hooks";
import { getNetworkIcon } from "@/util/common";
import { trackSessionStart } from "@/util/tracking";

import { numberFormatter } from "../../util/formatters";
import { Noop } from "../util";
import ClientOnly from "../util/client-only";
import ConnectWalletModal from "./connect-wallet";
import { AirdropButton, ButtonsRow, Logo, Nav } from "./styled";
import WalletModal from "./wallet-modal/wallet-modal";

const useLinkProps = (href?: string) => {
  const { push } = useRouter();

  useEffect(() => {
    trackSessionStart();
  }, []);

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (href) {
        push(href);
      }
    },
    [push, href],
  );
  return { onClick, href };
};

interface Props {
  chain: Chain;
  chains: Record<string, Chain>;
}

export default function Navigation({ chain, chains }: Props) {
  const chainId = useChainId();

  const {
    callback: consentSuccessCallback,
    setCallback: setConsentSuccessCallback,
  } = useConsentSuccess();

  const chainName = CHAINKEY_MAP[chain.id];
  const account = useAccount();
  const { chain: chainCheck } = account;
  const isWrongNetwork = chainId !== chainCheck?.id;

  const { connect$, navState$ } = useRx();
  const navState = useStateFrom(navState$);
  const pathname = usePathname();
  const router = useRouter();
  const baseUrl = useBaseUrl();
  const { error$ } = useRx();
  const [scroll, setScroll] = React.useState(0);
  const [isWalletModalVisible, setIsWalletModalVisible] = React.useState(false);
  const connect = useStateFrom(connect$);

  const hideWalletModal = () => {
    document.body.style.overflow = "visible";
    setIsWalletModalVisible(false);
  };

  const showLogo =
    pathname === `/${chainName}` ||
    pathname === `/${chainName}/earn` ||
    pathname === `/${chainName}/farm` ||
    pathname === `/${chainName}/all-trades`;

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const homeLink = useLinkProps("/" + chainName);

  const { chainPath } = useDexPartner();

  const { data: userPoints } = useMarginlyApi({
    enabled: Boolean(account.address),
    endpoint: "userPoints",
    args: [account.address],
  });

  const pointBalance = userPoints?.balance || 0;

  return (
    <>
      <Nav
        className={cn(
          {
            scrolled:
              scroll &&
              pathname.indexOf("withdraw") === -1 &&
              pathname.indexOf("deposit") === -1,
          },
          navState?.bgType,
        )}
      >
        {showLogo ? (
          <Logo {...homeLink} />
        ) : pathname.indexOf("success") !== -1 ? (
          <div />
        ) : (
          <a
            href={chainPath}
            onClick={(e) => {
              e.preventDefault();
              const prevPage = window.location.href;
              window.history.back();

              setTimeout(() => {
                if (window.location.href === prevPage) {
                  router.push(chainPath);
                }
              }, 300);
            }}
            style={{ marginLeft: "-8px" }}
          >
            <img
              src="/images/back-button.svg"
              alt=""
              style={{ filter: "invert(var(--svg-invert))" }}
            />
          </a>
        )}

        <ClientOnly>
          <ButtonsRow>
            <NextLink href="/sparks">
              <AirdropButton>
                <img src="/images/airdrop/airdrop-btn.svg" alt="" />
                <T action>
                  {pointBalance
                    ? numberFormatter(Math.ceil(pointBalance))
                    : "Get Sparks"}
                </T>
              </AirdropButton>
            </NextLink>
            <Button
              onClick={() => {
                document.body.style.overflow = "hidden";
                setIsWalletModalVisible(true);
              }}
              className={cn(
                account.address == null && "no-address",
                "selected-network",
                "marginly-brand-button",
              )}
              md
              icon
            >
              <img src={getNetworkIcon(chain.id)} alt="" />
              {account.address ? (
                <T action> ..{account?.address?.slice(-4)}</T>
              ) : (
                <T action>Connect</T>
              )}
            </Button>
          </ButtonsRow>
        </ClientOnly>

        {isWalletModalVisible ? (
          <WalletModal onHide={hideWalletModal} chain={chain} chains={chains} />
        ) : (
          <Noop />
        )}

        {connect ? (
          <ConnectWalletModal onHide={() => connect$.next(false)} />
        ) : (
          <Noop />
        )}

        {/* <ConsentModal
          onSuccess={(address: string) => {
            consentSuccessCallback?.(address);
            setConsentSuccessCallback(undefined);
          }}
          baseUrl={baseUrl}
          onError={error$.next}
          userAddress={account.address}
          useDisconnect={useDisconnect}
          // @ts-ignore
          useSignMessage={useSignMessage}
        /> */}
      </Nav>
    </>
  );
}
