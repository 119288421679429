"use client";

import { useCallback, useState } from "react";

import { ConsentSuccessContext } from "./context";
import { Callback } from "./types";

export function ConsentSuccessProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [callback, setCallbackState] = useState<Callback>();

  const setCallback = useCallback((value: Callback) => {
    setCallbackState(() => value);
  }, []);

  return (
    <ConsentSuccessContext.Provider value={{ callback, setCallback }}>
      {children}
    </ConsentSuccessContext.Provider>
  );
}
