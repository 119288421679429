import Button from "@marginly/ui/components/button";
import Card from "@marginly/ui/components/card";
import Cell, { CellItem } from "@marginly/ui/components/cell";
import Noop from "@marginly/ui/components/helpers/noop";
import T from "@marginly/ui/components/typography";
import type { ThemeConfig } from "@marginly/ui/stores/theme";
import copy from "copy-to-clipboard";
import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { t } from "ttag";
import { type Chain } from "viem";
import { useAccount, useDisconnect } from "wagmi";

import Space from "@/components/common/space";
import { CHAINKEY_MAP } from "@/constants/chains";
import { SwapRouter } from "@/constants/enum";
import { useDexPartner } from "@/contexts/partner";
import { useRx } from "@/contexts/rx";
import { useWeb3Context } from "@/contexts/web3";
import { useMarginlyApi } from "@/hooks/api";
import { parse0x, parse0xUnsafe } from "@/util/check";
import { getNetworkIcon } from "@/util/common";
import { capitalize, markWritable, noop } from "@/util/core";
import { chainRequest } from "@/util/wallet";

import Balance, { RestBalance } from "./balance";
import { useNetworks } from "./hooks";
import {
  Assets,
  NetworkArrow,
  NetworkLogo,
  NetworkModalTop,
  NetworkRow,
  Subheading,
  ThemeButton,
  ThemeButtonRight,
} from "./styled";
import { ModalType } from "./types";

interface Props {
  chain: Chain;
  chains: Record<string, Chain>;
  handleSwitchModal: (mt: ModalType, callback?: () => void) => void;
  onHide?: () => void;
  themeConfig: ThemeConfig;
  poolByToken?: Record<`0x${string}`, `0x${string}`[]>;
}

export default function ModalCommon({
  chain,
  chains,
  onHide,
  handleSwitchModal,
  themeConfig,
  poolByToken,
}: Props) {
  const chainName = CHAINKEY_MAP[chain.id];
  const router = useRouter();
  const account = useAccount();
  const { connect$, notification$ } = useRx();
  const { partner } = useDexPartner();
  const isRamses = !!partner;
  const { disconnect } = useDisconnect();

  const { pools } = useWeb3Context();

  const [mainnets, testnets] = useNetworks(chains);
  const hasNetworksChoice = mainnets.length > 1 || testnets.length > 0;

  const { set, tokens, nativeWrapper, count } = useMemo(() => {
    let nativeWrapper: `0x${string}` | undefined;
    const set = new Set<`0x${string}`>();
    const tokensByPool: Record<
      `0x${string}`,
      { base?: `0x${string}`; quote?: `0x${string}` }
    > = {};
    const count: Record<`0x${string}`, number> = {};

    for (const { baseToken, quoteToken, unwrap, address } of pools) {
      const base = baseToken ? parse0x(baseToken.toLowerCase()) : undefined;
      const quote = quoteToken ? parse0x(quoteToken.toLowerCase()) : undefined;
      tokensByPool[address] = { base, quote };
      if (base) {
        set.add(base);
        count[base] = (count[base] ?? 0) + 1;
      }

      if (quote) {
        set.add(quote);
        count[quote] = (count[quote] ?? 0) + 1;
      }

      if (unwrap) {
        nativeWrapper = parse0xUnsafe(
          { baseToken, quoteToken }[`${unwrap}Token`]?.toLowerCase(),
        );
      }
    }

    const tokens = Array.from(set);
    return { set, tokens, nativeWrapper, tokensByPool, count };
  }, [pools]);

  const aArgs = useMemo(
    () =>
      account.address
        ? markWritable([
            chainName,
            chain.testnet ? SwapRouter.Marginly : SwapRouter.Portals,
            account.address,
          ] as const)
        : undefined,
    [account.address, chain, chainName],
  );

  const { data: aggregates } = useMarginlyApi({
    enabled: Boolean(aArgs),
    endpoint: "local.balanceAggregate",
    args: aArgs!,
  });

  const restTokens = useMemo(
    () =>
      aggregates?.tokens?.filter((v) => {
        if (!v.address) {
          return false;
        }

        return !set.has(parse0x(v.address.toLowerCase()));
      }),
    [set, aggregates],
  );

  const handleCopy = useCallback(() => {
    copy(account.address || "");

    notification$.next({
      message: `Address copied.`,
      imgSrc: "/images/copy.svg",
    });
  }, [account.address, notification$]);

  return (
    <>
      {account.isConnected ? (
        <NetworkModalTop>
          <T headerM>
            {account.address?.slice(0, 4) + ".." + account.address?.slice(-4)}
          </T>
          <Button sm secondary onClick={() => handleCopy()}>
            <T action>{t`Copy`}</T>
          </Button>
        </NetworkModalTop>
      ) : (
        <NetworkModalTop>
          <T headerM style={{ color: "var(--text-tertiary)" }}>
            Well...
          </T>

          <Button
            sm
            onClick={() => {
              connect$.next(true);
              onHide?.();
            }}
          >
            {t`Connect Wallet`}
          </Button>
        </NetworkModalTop>
      )}

      <Space height={24} heightMobile={24} />

      <NetworkRow
        onClick={
          hasNetworksChoice
            ? handleSwitchModal.bind(null, "network", noop)
            : undefined
        }
        $clickable={hasNetworksChoice}
      >
        <NetworkLogo>
          <img src={getNetworkIcon(chain?.id)} alt="" />
        </NetworkLogo>
        <div>
          <Subheading>{t`Network`}</Subheading>
          {chain?.name}
        </div>

        {hasNetworksChoice && (
          <NetworkArrow>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 18L9.5 10V8L1.5 0L0 1.5L7.5 9L6.55671e-07 16.5L1.5 18Z"
                fill="var(--icon-tertiary)"
              />
            </svg>
          </NetworkArrow>
        )}
      </NetworkRow>

      {account.isConnected ? (
        <>
          <Space height={24} heightMobile={24} />
          <Assets>
            <T headerS>Assets</T>
            <Space height={8} heightMobile={8} />
            <Balance
              chainName={chainName}
              trade={
                nativeWrapper
                  ? handleSwitchModal.bind(null, nativeWrapper, noop)
                  : noop
              }
            />

            {tokens.map((token) => (
              <Balance
                chainName={chainName}
                trade={
                  poolByToken?.[token]?.length === count?.[token]
                    ? undefined
                    : handleSwitchModal.bind(null, token, noop)
                }
                key={token}
                address={token}
                unwrap={nativeWrapper === token}
              />
            ))}

            {restTokens?.map(({ address, ...rest }) => (
              <RestBalance key={address!} address={address!} {...rest} />
            ))}
          </Assets>
        </>
      ) : (
        <Noop />
      )}

      {account.isConnected ? (
        <>
          <Space height={24} heightMobile={24} />
          <Card
            style={{ width: "100%", cursor: "pointer", paddingLeft: "24px" }}
            onClick={() => {
              onHide?.();
              router.push(`/${chainName}/history`);
            }}
          >
            <Cell>
              <CellItem>
                <T headerS>History</T>
              </CellItem>
              <CellItem>
                <NetworkArrow>
                  <img
                    src="/images/history.svg"
                    alt=""
                    style={{ filter: "invert(var(--svg-invert))" }}
                  />
                </NetworkArrow>
              </CellItem>
            </Cell>
          </Card>
        </>
      ) : (
        <Noop />
      )}

      {!isRamses && (
        <>
          <Space height={24} heightMobile={24} />
          <ThemeButton onClick={handleSwitchModal.bind(null, "theme", noop)}>
            {t`Appearance`}
            <ThemeButtonRight>
              {themeConfig === "auto" ? "System" : capitalize(themeConfig)}

              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 18L9.5 10V8L1.5 0L0 1.5L7.5 9L6.55671e-07 16.5L1.5 18Z"
                  fill="var(--icon-tertiary)"
                />
              </svg>
            </ThemeButtonRight>
          </ThemeButton>
        </>
      )}

      {account.isConnected ? (
        <>
          <Space height={24} heightMobile={24} />
          <Button
            tertiary
            md
            style={{ width: "100%" }}
            onClick={() => disconnect()}
          >
            <T action>Log Out</T>
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
